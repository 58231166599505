<template>
  <vx-card :title="title">
    <div class="vx-row mb-6">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
      >Back
      </vs-button>
    </div>

    <div class="flex md:flex-row">
      <div class="w-full pr-6 md:basis-1/2">
        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>SOF ID</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="code" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Warehouse</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="warehouse" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Counting Type</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="countingType" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Document Date</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="countingDate" disabled readonly></vs-input>
          </div>
        </div>

        <div v-if="status === 3 && remark === 'Adjusted'" class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Posting Date</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="postingDate" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Cost Center</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="costCenter" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>External Code</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="externalCode" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Note</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-textarea class="w-full bg-grey-light" :value="note" disabled readonly/>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>System Qty As Of</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="systemQtyAsOf" disabled readonly></vs-input>
          </div>
        </div>

      </div>

      <div class="w-full md:basis-1/2">
        <div class="mb-6 vx-row">
          <vs-divider style="width: 50%; margin-left: 10%">
            List Attachment
          </vs-divider>
          <div
            class="vx-row mb-3 mt-6 w-4/4"
            style="width: 50%; margin-left: 10%"
          >
            <table class="vs-table vs-table--tbody-table">
              <template>
                <tr
                  class="tr-values vs-table--tr tr-table-state-null selected"
                  v-bind:key="tr.NameFile"
                  v-for="tr in fileAttachment"
                >
                  <td class="td vs-table--td">{{ tr.FileName }}</td>
                  <td class="td vs-table--td">
                    <vx-tooltip text="Show" v-if="tr.Path != ''">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-eye"
                        @click.stop="handleShowAttachment(tr.FileUrl)"
                      />
                    </vx-tooltip>
                  </td>

                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!--    table goes here -->
    <br>
    <vs-table
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
    >
      <template slot="thead">
        <vs-th>SKU</vs-th>
        <vs-th>Warehouse Area</vs-th>
        <vs-th>System Qty<br>[UOM]</vs-th>
        <vs-th>HU</vs-th>
        <vs-th>HU Qty</vs-th>
        <vs-th>UOM Qty</vs-th>
        <vs-th>Adjustment Qty<br>[UOM]</vs-th>
        <vs-th>Adjustment Value</vs-th>
        <vs-th>Adjusted<br>Batch External/ED/HU/Qty</vs-th>
        <vs-th>Remaining<br>Batch External/ED/HU/Qty</vs-th>
        <vs-th>Reason</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="`${tr.SkuCode} - ${tr.ItemName}`">
            {{ `${tr.SkuCode} - ${tr.ItemName}` }}
          </vs-td>
          <vs-td :data="`${tr.WarehouseAreaCode} - ${tr.WarehouseAreaName}`">
            {{ `${tr.WarehouseAreaCode} - ${tr.WarehouseAreaName}` }}
          </vs-td>
          <vs-td :data="tr.SystemQtyUom">
            {{ tr.SystemQtyUom }}
          </vs-td>
          <vs-td :data="tr.Unit">
            {{ tr.Unit }}
          </vs-td>
          <vs-td :data="tr.UnitQty">
            {{ tr.UnitQty }}
          </vs-td>
          <vs-td :data="`${tr.UnitUOM}/${tr.UomQty}`">
            {{ `${tr.UnitUOM}/${tr.UomQty}` }}
          </vs-td>
          <vs-td :data="formatAdjustmentQty(tr)">
            {{ formatAdjustmentQty(tr) }}
          </vs-td>
          <vs-td :data="tr.AdjustmentValue">
            {{ priceFormat(tr.AdjustmentValue) }}
          </vs-td>
          <vs-td :data="tr.Adjusted">
            <div v-for="(item, index) in parseJson(tr.Adjusted)" :key="item">
              {{ item }}<span v-if="index < parseJson(tr.Adjusted).length - 1">,</span>
            </div>
          </vs-td>
          <vs-td :data="tr.Remaining">
            <div v-for="(item, index) in parseJson(tr.Remaining)" :key="item">
              {{ item }}<span v-if="index < parseJson(tr.Remaining).length - 1">,</span>
            </div>
          </vs-td>
          <vs-td :data="tr.Reason">
            {{ tr.Reason }}
          </vs-td>
        </vs-tr>

        <vs-tr>
          <vs-td colspan="6"></vs-td>
          <vs-td><b>Total<br>Adjustment Value</b></vs-td>
          <vs-td><b>{{ priceFormat(calculateTotalAdjustmentValue()) }}</b></vs-td>
          <vs-td colspan="3"></vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vx-card>
</template>

<script>
import moment from "moment/moment";

export default {
  data() {
    return {
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      title: 'Stock Opname Form View',
      code: null,
      warehouse: null,
      countingType: null,
      countingDate: null,
      postingDate: null,
      costCenter: null,
      externalCode: null,
      note: null,
      systemQtyAsOf: null,
      fileAttachment: null,
      lines: [],
      status: null,
      remark: null,
      table: this.tableDefaultState(),
    }
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        transactionDate: null,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleShowAttachment(file) {
      window.open(file, "_blank");
    },
    handleBack() {
      this.$vs.loading();
      if(this.$route.query.is_approval === 1) {
        this.$router.push({
          name: "stock-opname-form-approval",
          query: { tab: this.$route.query.tab },
        });
      }else {
        this.$router.push({
          name: "stock-opname-form",
          query: { tab: this.$route.query.tab },
        });
      }
      this.$vs.loading.close();
    },
    getData() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/stock-opname-form/" + this.id).then((resp) => {
        if (resp.code === 200) {
          this.status = resp.data.sof.Status;
          this.remark = resp.data.Remark;
          this.code = resp.data.sof.Code;
          this.warehouse = resp.data.sof.WarehouseCode + " - " + resp.data.sof.WarehouseName;
          this.countingDate = moment(resp.data.sof.CountingDate).format('YYYY-MM-DD');
          this.postingDate = moment(resp.data.sof.PostingDate).format('YYYY-MM-DD');
          this.countingType = resp.data.sof.CountingType;
          this.costCenter = resp.data.sof.CostCenterCode + " - " + resp.data.sof.CostCenterName;
          this.externalCode = resp.data.sof.ExternalCode;
          this.note = resp.data.sof.Note;
          this.systemQtyAsOf = moment(resp.data.sof.SystemQtyAsOf).format('YYYY-MM-DD HH:mm:ss');
          this.fileAttachment = resp.data.sof_attachment;
          this.table.data = resp.data.sofl;
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check"
          });
        }
        this.$vs.loading.close();
      });
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      return moment(date).format('DD MMM YYYY');
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    formatAdjustmentQty(tr) {
      if (tr.Status === 0 && tr.AdjustmentQtyUOM !== null && tr.AdjustmentQtyUOM !== undefined) {
        return `-${tr.AdjustmentQtyUOM}`;
      } else if (tr.AdjustmentQtyUOM === 0) {
        return "0";
      } else if (tr.Status === 1 && tr.AdjustmentQtyUOM !== null && tr.AdjustmentQtyUOM !== undefined) {
        return `+${tr.AdjustmentQtyUOM}`;
      }
      return tr.AdjustmentQtyUOM ? tr.AdjustmentQtyUOM : '';
    },
    calculateTotalAdjustmentValue() {
      return this.table.data.reduce((total, item) => {
        return total + parseFloat(item.AdjustmentValue || 0);
      }, 0);
    },
    parseJson(jsonString) {
      try {
        return jsonString ? JSON.parse(jsonString) : [];
      } catch (e) {
        return [];
      }
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
}
</script>
